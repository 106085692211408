export const seasonalPartsFragment = `
  fragment SeasonalParts on Seasonal {
    ceremonyVenue
    receptionVenue
    offPeak {
      ...SeasonalPricingParts
    }
    peak {
      ...SeasonalPricingParts
    }
  }
`;
export const seasonalPricingPartsFragment = `
  fragment SeasonalPricingParts on SeasonalPricing {
    services {
      reception
      ceremony
      barServices
      months
    }
    minPrice
    maxPrice
    averagePrice
  }
`;
