import axios, { type AxiosResponse } from 'axios';

import graphqlApiUrl from './helpers/graphqlApiUrl';

import categoryFiltersQuery from './graphql/categoryFilters';

export default function categoryFilters(
	categoryId: string,
	visitorId: string,
): Promise<AxiosResponse<API.CategoryFiltersResponse>> {
	const data = {
		query: categoryFiltersQuery,
		variables: {
			categoryGuid: categoryId,
		},
	};

	const config = {
		headers: { participantId: visitorId },
	};

	// Todo: On Classic we are caching these for 1 week.
	return axios.post(graphqlApiUrl(), data, config);
}
