import storefront from './storefrontFragment';

export const storefrontsQuery = `
  query($ids: [ID!]) {
    storefronts(ids: $ids, allMedia: true) {
      profiles {
        ...ProfileProperties
      }
    }
  }
  ${storefront}
`;

export const chatRecommendationStorefrontsQuery = `
  query GetChatRecommendationStorefronts($ids: [ID]!) {
    storefronts(ids: $ids) {
      profiles {
        id
        name
        description
        marketCode
        siteUrls {
          siteId
          siteName
          uri
          relativeUri
        }
        reviewSummary {
          count
          overallRating
        }
        location {
          address {
            address1
            city
            state
          }
          serviceArea
        }
        storefrontCard {
          url
        }
        categories {
          id
          code
        }
        facets {
          id
          name
          facets {
            id
            name
            facets {
              id
              name
              facets {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const semanticSearchStorefrontsQuery = `
  query GetSemanticSearchStorefrontsQuery($ids: [ID]!) {
    storefronts(ids: $ids) {
      profiles {
        vendorBehavior {
          quickResponder
        }
        mediaSummary {  
          media {
            ... on MediaPhoto {
              id
              width
              height
              url
              mediaType
            }
            ... on MediaVideo {
              id
              thumbnailUrl
              url
              mediaType
            }
            ... on MediaTour {
              id
              thumbnailUrl
              url
              mediaType
              embedFormat
            }
          }
        }
        id
        name
        dek {
          description
        }
        marketCode
        siteUrls {
          siteId
          siteName
          uri
          relativeUri
        }
        reviewSummary {
          count
          overallRating
        }
        location {
          address {
            address1
            city
            state
          }
          serviceArea
        }
        storefrontCard {
          url
        }
        categories {
          id
          code
        }
        facets {
          id
          name
          facets {
            id
            name
            facets {
              id
              name
              facets {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
