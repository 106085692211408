import { graphqlFetch } from '@api/graphql/gql-fetch';
import axios from 'axios';

import {
	chatRecommendationStorefrontsQuery,
	semanticSearchStorefrontsQuery,
	storefrontsQuery,
} from '../graphql/storefronts';
import graphqlApiUrl from '../helpers/graphqlApiUrl';

export default function storefronts({ ids }) {
	return axios.post(graphqlApiUrl(), {
		query: storefrontsQuery,
		variables: {
			ids,
		},
	});
}

export function chatRecommendationStorefronts({ ids }) {
	return graphqlFetch({
		query: chatRecommendationStorefrontsQuery,
		variables: {
			ids,
		},
	});
}

export function semanticSearchStorefronts({ ids }) {
	return graphqlFetch({
		query: semanticSearchStorefrontsQuery,
		variables: {
			ids,
		},
	});
}
