import graphqlApiUrl from '@api/helpers/graphqlApiUrl';

interface Props<TVariables> {
	url?: RequestInfo | URL;
	query: string;
	variables?: TVariables;
	headers?: Headers;
}

export interface GqlResponse<TResponse> {
	data: TResponse;
}

export const graphqlFetch = async <TResponse, TVariables = {}>({
	url = graphqlApiUrl(),
	query,
	variables,
	headers,
}: Props<TVariables>): Promise<GqlResponse<TResponse>> => {
	const internalHeaders = new Headers();
	internalHeaders.append('Content-Type', 'application/json');

	headers?.forEach((value, key) => {
		if (internalHeaders.has(key)) {
			internalHeaders.set(key, value);
			return;
		}

		internalHeaders.append(key, value);
	});

	const options: RequestInit = {
		method: 'POST',
		body: JSON.stringify({
			query,
			variables,
		}),
		headers: internalHeaders,
	};

	const response = await fetch(url, options);

	return response.json();
};
