export default `
  query(
    $categoryGuid: String!,
  ){
    filterSummary(categoryGuid: $categoryGuid) {
    id
    name
      filters {
        name
        slug
        isSingleSelect,
        filterOptions {
          id
          name
          display {
            patternsIconClass
          }
          singular {
            slug
            term
          }
          plural {
            slug
            term
          }
        }
      }
    }
  }
`;
